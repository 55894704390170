import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import styles from './header.module.css';

export default function Header() {
  return (
    <div className={styles.header}>
      <Helmet title="Sidd" />
      <h1 className={styles.logo}>
        <Link to="/" className={styles.logoLink}>
          Sidd
        </Link>
      </h1>
      <ul className={styles.nav}>
        <li className={styles.navItem}>
          <Link to="/">Blog</Link>
        </li>
        <li className={styles.navItem}>
          <Link to="/about">About</Link>
        </li>
      </ul>
    </div>
  );
}
