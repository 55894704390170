import React from 'react';

import styles from './footer.module.css';

export default function Footer() {
  return (
    <>
      <hr />
      <div className={styles.footer}>
        <ul className={styles.socials}>
          <li className={styles.social}>
            <a
              href="https://twitter.com/siddsridharan"
              rel="noopener noreferrer"
              className="no-extern-badge"
            >
              Twitter
            </a>
          </li>
          <li className={styles.social}>
            <a
              href="https://linkedin.com/in/siddsridharan"
              rel="noopener noreferrer"
              className="no-extern-badge"
            >
              LinkedIn
            </a>
          </li>
          <li className={styles.social}>
            <a
              href="https://github.com/sidd"
              rel="noopener noreferrer"
              className="no-extern-badge"
            >
              GitHub
            </a>
          </li>
          <li className={styles.social}>
            <a
              href="mailto:sidd@sidd.com"
              rel="noopener noreferrer"
              className="no-extern-badge"
            >
              Email
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}
